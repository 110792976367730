import React, { Component } from "react";
import PropTypes from "prop-types";
import isEqual from "react-fast-compare";
import { isTablet } from "react-device-detect";

import {
  indexColorMapper,
  tensorFromMatrixData,
  isTextHighContrast,
  getTotalReturns,
  getReturnsByYear,
  getFormattedPercent,
  getRangeIndex
} from "../utilities";

import "./DomGrid.css";

class DomGrid extends Component {
  constructor(props) {
    super(props);
    this.domgridRef = React.createRef();
    this.rangeFrequencies = [];
  }
  shouldComponentUpdate(nextProps) {
    // only re-render this expensive component when the props have deeply changed
    const shouldUpdate = !isEqual(this.props, nextProps);
    return shouldUpdate;
  }
  onCellClick(coords) {
    console.log(this.props);
    if (this.props.dragMode) return;
    const gridData = this.getGridData();
    const cellData = gridData.get(coords[0], coords[1], 0);
    const selectedCell = cellData ? coords : null;
    this.props.onCellClick(selectedCell);
  }

  getGridData() {
    return tensorFromMatrixData(this.props.currentMatrix).step(-1, 1);
  }
  renderGrid() {
    let gridData = this.getGridData();
    let timePeriod = this.props.timePeriod;
    let gridCells = gridData.tolist();
    // let gridHeight = gridData.shape[0];
    let gridWidth = gridData.shape[1];
    let gridColumns = gridWidth;

    let { visibleHeight } = this.props;
    // const drawerHeightCSSVar = getComputedStyle(document.documentElement).getPropertyValue('--drawer-height');
    // const drawerHeight = parseInt(drawerHeightCSSVar, 10) || 40;

    visibleHeight = visibleHeight - 58;

    if (isTablet) {
      visibleHeight = visibleHeight - 140;
    }

    let cellPadding;
    let cellHeight = visibleHeight / gridWidth;
    let cellHeightBeforeDiv = cellHeight;

    if (gridWidth <= 30) {
      cellHeight = cellHeight / 35;
      cellPadding = 0.2;
    } else {
      cellHeight = cellHeight / 31;
      cellPadding = 0.05;
    }

    // console.log('grid info ', gridData)
    // console.log("grid width ", gridWidth);
    // console.log(`cellHeight  ${cellHeight}`);

    const cellHeightStyle = `${cellHeight}rem`;

    document.documentElement.style.setProperty(
      "--cell-heightpx",
      `${cellHeightBeforeDiv}px`
    );
    document.documentElement.style.setProperty(
      "--cell-height",
      cellHeightStyle
    );
    document.documentElement.style.setProperty(
      "--cell-padding",
      `${cellPadding}rem`
    );

    let grid = [];
    let headerRow = [];

    // add y-axis to the grid
    grid.push(
      <div
        className="domgrid__col domgrid__cell"
        key={timePeriod}
        style={{
          fontSize: cellHeightStyle,
        }}
      >
        {headerRow}
      </div>
    );

    const returnPeriod = this.props.highlightReturnsPeriod; //[10, 15]
    const returnCells = [];

    if (returnPeriod) {
      let startRow = gridColumns - returnPeriod[1];
      let endRow = gridColumns - returnPeriod[0];

      // Highlight return period
      for (let col = 0; col < gridColumns; col++) {
        for (let row = startRow; row < endRow; row++) {
          const coords = [col, row - col];
          const coordKey = coords[1] + ":" + coords[0];
          returnCells.push(coordKey);
        }
      }
    }

    let i = 0;
    for (let row = 0; row <= gridColumns; row++) {
      const cols = [];
      let previousCellHadValue = true;
      const currentYear = timePeriod[0] + i;

      const headerValue = gridColumns - row; // currentYear
      const isNotLastRow = row !== gridColumns;
      cols.push(
        <div
          className={"domgrid__header domgrid__x-axis domgrid__cell"}
          style={{
            visibility: isNotLastRow ? "visible" : "hidden",
            color: isNotLastRow && "#797979",
            fontSize: cellHeightStyle,
          }}
          key={i + "header"}
        >
          {/* adding support for showing the latest year on the very right */}
          {isNotLastRow ? headerValue : "*"}
        </div>
      );
      for (let col = 0; col < gridColumns; col++) {
        let cellValue = gridData.get(col, row, 0);
        let coords = [col, row];
        let coordKey = coords[0] + ":" + coords[1];
        let cellOverideData = gridCells[coordKey] ? gridCells[coordKey] : {};
        const TEXT_COLOR_LIGHT = "#fcfcfb";
        const TEXT_COLOR_DARK = "#666";

        let textColor =
          isTextHighContrast(cellValue) &&
          JSON.stringify(returnPeriod) === JSON.stringify([0, 0])
            ? TEXT_COLOR_LIGHT
            : TEXT_COLOR_DARK;

        let colStyle = {
          background: indexColorMapper(
            [cellValue],
            JSON.stringify(returnPeriod) === JSON.stringify([0, 0]) ? 1 : 0.1
          ),
          color: textColor,
          //cursor: 'pointer'
        };  

        if (this.props.selectedCell) {
          let textColor = isTextHighContrast(cellValue)
            ? TEXT_COLOR_LIGHT
            : TEXT_COLOR_DARK;
          colStyle.background = indexColorMapper([cellValue], 0.1);
          colStyle.color = textColor;

          let isCellInlineSelected =
            cellValue !== 0 &&
            (this.props.selectedCell[0] === coords[0] ||
              this.props.selectedCell[1] === coords[1]);

          if (isCellInlineSelected) {
            colStyle.background = indexColorMapper([cellValue], 1);
          } else {
            colStyle.color = TEXT_COLOR_DARK;
          }

          let isCellSelected =
            this.props.selectedCell[0] === coords[0] &&
            this.props.selectedCell[1] === coords[1];

          if (isCellSelected) {
            colStyle.border = "1px solid black";
          }
        }

        // highlight color when a range is selected
        if (returnCells.includes(coordKey)) {
          let textColor = isTextHighContrast(cellValue)
            ? TEXT_COLOR_LIGHT
            : TEXT_COLOR_DARK;
          colStyle.background = indexColorMapper([cellValue], 1);
          colStyle.color = textColor;
        }
        const selectedCell  = this.props.selectedCell ? this.props.selectedCell: null;
        const endYear = selectedCell ? timePeriod[1] - selectedCell[0] : null;
        const startYear = selectedCell ? timePeriod[0] + selectedCell[1] : null; 
        const isSelectedYear = endYear === currentYear-1 || startYear === currentYear-1;
        // we know we are at the end of a column when:
        // the current cell is 0 and the previous cell had a value
        if (!cellValue && previousCellHadValue) {
          previousCellHadValue = false;
          cols.push(
            <div
              className="domgrid__header domgrid__x-axis domgrid__cell"
              style={{
                fontSize: isSelectedYear ? `${18/this.props.zoom}px` : cellHeightStyle,
                fontWeight: 'bold',
                position: isSelectedYear ? 'absolute' : 'relative',
                margin  : isSelectedYear ? `-${cellHeight/this.props.zoom}px 0px 0px ${10/this.props.zoom}px` : '0px',
                opacity: isSelectedYear || !selectedCell ? 1 : 0.25
              }}
              key={currentYear + "header"}
            >
              {/* To better match the poster, we want to shift the numbers by one */}
              {currentYear - 1}
            </div>
          );
        } else if (row !== gridColumns && cellValue) {
          const rangeIndex = getRangeIndex(cellValue);
          this.rangeFrequencies[rangeIndex] = this.rangeFrequencies[rangeIndex] ? this.rangeFrequencies[rangeIndex]+1 : 1;
          const hoveredCell = this.hoveredCell ? this.hoveredCell : null;
          const isHovered = hoveredCell ? hoveredCell[0] === coords[0] &&  hoveredCell[1] === coords[1] : null;
          // add returns value to each data cell
          cols.push(
            <div
              className="domgrid__cell"
              key={col + "d" + row + "ff"}
              style={{
                ...colStyle,  
                fontSize: cellHeightStyle,
                background: isHovered ? colStyle.background.replace(/[^,]+(?=\))/, 1) : colStyle.background
              }}
              data-coords={coords}
              id={coordKey}
              onClick={this.onCellClick.bind(this, coords)}
              {...cellOverideData}
            > 
              <div
                className="domgrid__cell-value"
                style={{
                  visibility:
                    this.props.showReturns && cellValue ? "visible" : "hidden",
                }}
              >
                {cellValue ? cellValue.toFixed(1) : "0"}
              </div>
            </div>
          );
        }
      }

      // add x-axis columns to grid
      grid.push(
        <div className="domgrid__col" key={row}>
          {cols}
        </div>
      );
      i++;
    }
    return grid;
  }
  render() {
    this.rangeFrequencies = [];
    // don't show anything until we get the visible height
    // prevents some visual jank of showing smaller items then bigger
    if (!this.props.visibleHeight) {
      return null;
    }
    return <div id="matrix" className="domgrid">{this.renderGrid()}</div>;
  }
  componentDidUpdate() {
    this.props.handleUpdateRangeFrequencies(this.rangeFrequencies);
  }
  componentDidMount() {
    this.props.handleUpdateRangeFrequencies(this.rangeFrequencies);
    if (isTablet) return;
    const timePeriod = this.props.timePeriod;
    const that = this;
    let selectedCell = null;
    // TODO: create absolutely positioned div with year range pinned to cell position 
    let hoverLabel = document.getElementById('hover-label');
    if (!hoverLabel) {
      hoverLabel = document.createElement('div');
      document.body.appendChild(hoverLabel);
      hoverLabel.id = 'hover-label';
      Object.assign(hoverLabel.style, {
        position:'absolute',
        display:'none',
        zIndex:1000
      });
    }
    document.getElementById('sidebar').addEventListener('mouseover',(e) => {
      e.stopPropagation();
      hoverLabel.style.display = 'none';
    });
    document.addEventListener('mouseover',(e) => {
      if (e.target.className !== 'domgrid__cell' && e.target.className !== 'domgrid__cell-value') hoverLabel.style.display = 'none';
    });
    setTimeout(() => {
      Array.from(document.getElementsByClassName('domgrid__cell')).forEach((cell) => {
        cell.addEventListener('click',(e) => {
            Array.from(document.getElementsByClassName('hovered')).forEach((hovered) => hovered.classList.remove('hovered'));
            if (that.props.dragMode) return;
            selectedCell = cell;
            const returnsLabel = cell.children[0];
            const showReturns = returnsLabel ? returnsLabel.style.visibility === 'visible' : false;
            hoverLabel.style.display = showReturns ? 'block' : 'none';
        });
        cell.addEventListener('mouseenter',(e) => {
          if (that.props.dragMode) return;
          const coords = e.target.getAttribute('data-coords') ? e.target.getAttribute('data-coords').split(',') : null;
          const endYear = coords ? timePeriod[1] - coords[0] : null;
          const startYear = coords ? timePeriod[0] + parseInt(coords[1]) : null; 
          if (endYear < startYear || !coords) { hoverLabel.style.display = 'none'; return; }
          const returnsLabel = cell.children[0];
          const showReturns = returnsLabel ? returnsLabel.style.visibility === 'visible' : false;
          const hoveredCell = e.target;
          // TODO: add class instead 
          hoveredCell.classList.add('hovered');
          const left = hoveredCell.getBoundingClientRect().left;
          const top = hoveredCell.getBoundingClientRect().top;
          Object.assign(hoverLabel.style,{
            display: coords === selectedCell ? 'none' : 'block',
            left: `${left+e.target.getBoundingClientRect().width}px`,
            top: `${top}px`,
            backgroundColor: 'rgba(256,256,256,0.8)',
            fontSize:'95%',
            padding: showReturns ? '5px' : '2px'
          });
          const yearRange = '<b>' + startYear + '-' + endYear + '</b>';
          const annualizedReturnLabel = `<br><span style="font-size:90%">Annualized Return: ${returnsLabel.innerHTML}%</span>`;
          const totalReturns = getTotalReturns(getReturnsByYear(that.props.currentMatrix, { startYear, endYear }));
          const totalReturnLabel = `<br><span style="font-size:90%">Total Return: ${getFormattedPercent(totalReturns)}</span>`;

          hoverLabel.innerHTML = yearRange + annualizedReturnLabel + totalReturnLabel;
          e.stopPropagation();
        });
        cell.addEventListener('mouseleave',(e) => {
          if (that.props.dragMode) return;
          if (selectedCell === cell) { e.target.classList.remove('hovered'); return; }
          e.target.classList.remove('hovered')
          e.stopPropagation();
        });
      });
    },200);
  }
}

DomGrid.propTypes = {
  currentMatrixId: PropTypes.string.isRequired,
  currentMatrix: PropTypes.any.isRequired, // TODO: define shape
  showReturns: PropTypes.any.isRequired, // TODO: define shape
  selectedCell: PropTypes.any, // TODO: define shape
  timePeriod: PropTypes.array.isRequired, // TODO: define shape
  highlightReturnsPeriod: PropTypes.any.isRequired,
  visibleHeight: PropTypes.number,
  onCellClick: PropTypes.func.isRequired,
  rangeFrequencies: PropTypes.array,
  dragMode: PropTypes.bool
};

export default DomGrid;
