import Decimal from "decimal.js";
import { std } from "mathjs";
import nj from "./numjs";

/** Start MATH functions */
export function getReturnsByYear(indexMatrix, dateRangeFilter) {
  // console.log('-------');
  const {
    rawRecordArray,
    meta: {
      timePeriod: [start],
    },
  } = indexMatrix;
  let returnsByYear = rawRecordArray.map((item, index) => {
    const returnRate = item[item.length - 1];
    // console.log(start + index, returnRate);
    return {
      year: start + index,
      returnRate: returnRate,
      annualizedReturn: 1 + returnRate / 100,
    };
  });
  // console.log('-------');

  // if date rang filter exists, filter by the start and end timeframe
  if (dateRangeFilter) {
    const { startYear, endYear } = dateRangeFilter;
    returnsByYear = returnsByYear.filter(({ year }) => {
      return between(year, startYear, endYear);
    });
  }

  // calculate growth of a dollar based on filtered timeframe
  let dollarValue = 1;
  returnsByYear = returnsByYear.map((item) => {
    const { returnRate } = item;
    const currentDollarValue = dollarValue + dollarValue * (returnRate / 100);
    dollarValue = currentDollarValue;
    return {
      ...item,
      growthOfDollar: currentDollarValue,
    };
  });

  return returnsByYear;
}

export function between(n, min, max) {
  return n >= min && n <= max;
}

// =POWER(PRODUCT(A1:A5), 1/COUNT(number of returns)) - 1
// A1 => 1 + return
export function getAnnualizedReturns(data) {
  const base = data.reduce((accumulator, { annualizedReturn }) => {
    return accumulator.times(annualizedReturn);
  }, new Decimal(1));
  const exponent = 1 / data.length;
  let result = base.pow(exponent);
  result = result.minus(1);
  return result.toNumber();
}

/*
	Get Annulized Standard Deviation
	----
	How to calculate the annualized standard deviation for a given data set:

	1. Get the average value of the data set
	2. Calculate the difference between each value in the set and the average
	3. Square the result of each difference
	4. Average the squared differences
	5. Get the square root of the average squared difference (aka square root of variance)

	// Google SHeets Formula
	// =SQRT(sum(E11:E12)/(count(A11:A12)-1))
	// E11 => =POWER(average - returnRate, 2)
	---
	The Annualized number is presented as an approximation by multiplying the 
	monthly number by the square root of the number of periods in a year (12).
	
	To get the standard deviation divided by the square root of 12.
*/
export function getStandardDeviation(data) {
  if (data.length === 0 || data.length === 1) {
    return;
  }

  // const average = getAverageReturn(data);
  // const numerator = data.reduce((acc, { returnRate }) => {
  // 	const result = Math.pow(average - returnRate, 2);
  // 	return acc + result;
  // }, 0);
  // const denominator = data.length - 1;
  // // when there is 1 year selected, there is no standard deviation
  // const annualizedStandardDeviation = Math.sqrt((numerator / denominator));
  // // const standardDeviation = annualizedStandardDeviation / Math.sqrt(12);
  // const standardDeviation = annualizedStandardDeviation;

  const returnRates = data.map(({ returnRate }) => returnRate);
  // some indexes dont have data to compare in certain timeperiods with other indexes
  if (returnRates.length === 0 || returnRates.length === 1) {
    return;
  }
  const standardDeviation = std(returnRates);

  return standardDeviation;
}

// =product(annualizedReturns) - 1
export function getTotalReturns(data) {
  if (data.length === 0) {
    return;
  }
  let returns = data.reduce((accumulator, { annualizedReturn }) => {
    return accumulator.times(annualizedReturn);
  }, new Decimal(1));
  let result = returns.minus(1);
  // TODO: HANDLE 1% => n/a
  return result.toNumber();
}

/* private - but exported so we can test */
export function getAverageReturn(data) {
  const total = data.reduce((sum, { returnRate }) => {
    return sum + returnRate;
  }, 0);
  const average = total / data.length;
  return average;
}

/** end MATH functions */

export function getShortMatrixName(name) {
  return name
    .replace("Fama/French", "")
    .replace("Index", "")
    .replace("Research", "");
}
export function getFormattedCurrency(input, digits = 2, currencyCode = "USD") {
  if (Number.isNaN(input) || typeof input === "undefined") {
    return "n/a";
  }
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currencyCode,
    minimumFractionDigits: digits,
  }).format(input);
}

export function getFormattedPercent(input, digits = 2) {
  if (Number.isNaN(input) || typeof input === "undefined") {
    return "n/a";
  }
  return new Intl.NumberFormat("en-US", {
    style: "percent",
    minimumFractionDigits: digits,
  }).format(input);
}

export function getFormattedStandardDeviation(input, digits = 2) {
  if (Number.isNaN(input) || typeof input === "undefined") {
    return "n/a";
  }
  input = parseFloat(input);
  return `${input.toFixed(digits)}%`;
}

export function pluralize(count, noun, suffix = "s") {
  return `${count} ${noun}${count !== 1 ? suffix : ""}`;
}

/* unused */
export function convertRemToPixels(rem) {
  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}

export const timePeriodFromCoords = (coords, originalPeriod) => {
  if (!coords) {
    return null;
  }
  let timePeriod = [
    originalPeriod[0] + coords[1],
    originalPeriod[1] - coords[0],
  ];
  return timePeriod;
};

function fillArrayWithNumbers(n) {
  var arr = Array.apply(null, Array(n));
  return arr.map(function (x, i) {
    return 0;
  });
}

export const tensorFromMatrixData = (matrixData) => {
  let matrixRecords = matrixData.rawRecordArray.map((record) => {
    let complement = fillArrayWithNumbers(
      matrixData.rawRecordArray.length - record.length
    );
    return record.concat(complement);
  });
  let tensor = nj
    .array(matrixRecords)
    .reshape(
      matrixData.rawRecordArray.length,
      matrixData.rawRecordArray.length,
      1
    );
  return tensor;
};

export function isTextHighContrast(value) {
  if (value < -12 || value > 16) {
    return true;
  } else return false;
}

export const ranges = () => {
  return [
    // Negatives
  // -------------------------------------
  // darkest red
  // anything less than -12% should have white text
    [null, -28, [143, 0, 0]],
    [-28 , -24, [198, 0, 0]],
    [-24 , -20,  [248, 0, 0]],
    [-20 , -16, [255, 0, 0]],
    [-16, -12, [255, 71, 65]],
    [-12, -8, [255, 128, 123]],
    [-8, -4, [255, 175, 171]],

    // lighest red
    [-4, 0, [255, 216, 215]],

    // lightest green
    [0, 4, [220, 240, 210]],

    // Positives
    // -------------------------------------
    // darkest green
    // anything greater than 16% should have white text
    [4, 8, [196, 237, 177]],
    [8, 12, [162, 229, 133]],
    [12, 16, [126, 220, 82]],
    [16, 20, [89, 196, 46]],
    [20, 24, [79, 164, 37]],
    [24, 28, [60, 128, 27]],
    [28, null, [42, 92, 16]]
  ];
}

export const rangeLabels = [
  '<-28%',
  '<-24% to -28%',
  '<-20% to -24%',
  '<-16% to -20%',
  '<-12% to -16%',
  '<-8% to -12%',
  '<-4% to -8%',
  '<0 to -4%',
  '0 to 4%',
  '>4% to 8%',
  '>8% to 12%',
  '>12% to 16%',
  '>16% to 20%',
  '>20% to 24%',
  '>24% to 28%',
  '>28%'
]

export const rangeColors = [
  [143, 0, 0],
  [198, 0, 0],
  [248, 0, 0],
  [255, 0, 0],
  [255, 71, 65],
  [255, 128, 123],
  [255, 175, 171],
  [255, 216, 215],
  [220, 240, 210],
  [196, 237, 177],
  [162, 229, 133],
  [126, 220, 82],
  [89, 196, 46],
  [79, 164, 37],
  [60, 128, 27],
  [42, 92, 16]
];

// https://monosnap.com/file/vGKPKgREg2Gn2RbgFBGYlLHf9ztA45
export const getRangeIndex = (value) => {
  let rangeKey;

  if (value <= -28) {
    rangeKey = 0;
  } else if (value <= -24 && value > -28) {
    rangeKey = 1;
  } else if (value <= -20 && value > -24) {
    rangeKey = 2;
  } else if (value <= -16 && value > -20) {
    rangeKey = 3;
  } else if (value <= -12 && value > -16) {
    rangeKey = 4;
  } else if (value <= -8 && value > -12) {
    rangeKey = 5;
  } else if (value <= -4 && value > -8) {
    rangeKey = 6;
  } else if (value < 0 && value >= -4) {
    rangeKey = 7;
  }

  // Positives
  // -------------------------------------
  // darkest green
  // anything greater than 16% should have white text
  else if (value > 28) {
    rangeKey = 15;
  } else if (value > 24 && value <= 28) {
    rangeKey = 14; 
  } else if (value > 20 && value <= 24) {
    rangeKey = 13;
  } else if (value > 16 && value <= 20) {
    rangeKey = 12;
  } else if (value > 12 && value <= 16) {
    rangeKey = 11;
  } else if (value > 8 && value <= 12) {
    rangeKey = 10;
  } else if (value > 4 && value <= 8) {
    rangeKey = 9;
  }
  // lightest green
  else if (value > 0 && value <= 4) {
    rangeKey = 8;
  }
  
  return rangeKey;
};

export const getIndexValueColor = (value, background = [255, 255, 255]) => {
  const rangeIndex = getRangeIndex(value[0]);
  return [rangeColors[rangeIndex] ? rangeColors[rangeIndex] : background];
}

export const indexColorMapper = (value, opacity) => {
  let color = getIndexValueColor(value);
  color.push(opacity || 1.0);
  // color.push(1.0);
  let str = `rgba(${color})`;
  return str;
  // var color = Color(str);
};
