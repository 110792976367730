import React from "react";
import PropTypes from "prop-types";

// https://create-react-app.dev/docs/adding-images-fonts-and-files/#adding-svgs
import { ReactComponent as ChevronUp } from "../../../icons/ChevronUp.svg";
import { ReactComponent as ChevronDown } from "../../../icons/ChevronDown.svg";

function DrawerHandle({ title, isOpen, handleClick, styles }) {
  return (
    <div className="drawer__handle" onClick={handleClick} style={styles}>
      <span className="drawer__title">{title}</span>
      {isOpen ? <ChevronUp /> : <ChevronDown />}
    </div>
  );
}

DrawerHandle.propTypes = {
  title: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
  style: PropTypes.object,
};

export default DrawerHandle;
