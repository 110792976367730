import React, {Component} from 'react';
import FadeIn from 'react-fade-in';
import * as Sentry from '@sentry/react';

import AppControls from '../AppControls';
import DomGrid from '../DomGrid';
import Topbar from '../Topbar';
import Draggable from 'react-draggable';
import {timePeriodFromCoords, getShortMatrixName} from '../utilities';

import {
  getIndexes,
  indexGroupOrder,
  indexOrder,
  nameToGroupMapper,
} from '../data/index';

import {Inflation, Coordinates, IndexMatrix, TimePeriod} from '../types';
import './App.css';

type Props = {
};

interface State {
  currentMatrixId: string;
  availableIndexes: any; // IndexMatrix
  showReturns: boolean;
  rangeFrequencies: Array<number> | null;
  applyInflation: boolean;
  selectedCell: Coordinates | null;
  hoveredCell: Coordinates | null;
  highlightReturnsPeriod: Coordinates;
  visibleHeight: number | null;
  visibleWidth: number | null;
  zoom: number;
  scale: number;
  dragMode: boolean;
  dragOffsetX: any | undefined;
  selectText: string;
}

const initialState = {
  currentMatrixId: 'Fama_French_Total_US_Market_Research_Index',
  availableIndexes: getIndexes('before'),
  showReturns: false,
  rangeFrequencies: null,
  applyInflation: false,
  selectedCell: null,
  hoveredCell: null,
  highlightReturnsPeriod: [0, 0],
  visibleHeight: null,
  visibleWidth: null,
  zoom: 1,
  scale: 1,
  dragMode: false,
  dragOffsetX: 0,
  selectText: ''
};

class App extends Component<Props, State> {

  longPress;
  dragRef: React.RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);
    this.state = initialState as State;
    this.resize = this.resize.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.handleSelectMatrix = this.handleSelectMatrix.bind(this);
    this.handleSelectInflation = this.handleSelectInflation.bind(this);
    this.handleShowReturns = this.handleShowReturns.bind(this);
    this.handleSelectTimeframe = this.handleSelectTimeframe.bind(this);
    this.handleOnCellClick = this.handleOnCellClick.bind(this);
    this.clearSelection = this.clearSelection.bind(this);
    this.handleUpdateRangeFrequencies = this.handleUpdateRangeFrequencies.bind(this);
    this.handleZoom = this.handleZoom.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleDraggable = this.handleDraggable.bind(this);
    this.dragRef = React.createRef();
  }
  componentDidMount() {
    this.updateWindowDimensions();
    setTimeout(() => {
      this.resize();
    },500);
    // @ts-ignore
    window.addEventListener('resize', this.resize);
  }

  resize() {
    const width = window.innerWidth || document.body.clientWidth;
    this.setState(() => ({ scale: width/1700 }));
    this.handleZoom(1);
    this.handleReset();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  // listen for resize events and update state with new height of wrapper container
  // TODO: this makes the matrix render twice, see if you can prevent that
  updateWindowDimensions() {
    // first get the size from the window
    // if that didn't work, get it from the body;
    const width = window.innerWidth || document.body.clientWidth;
    const height = window.innerHeight || document.body.clientHeight;
    this.setState(() => ({visibleWidth: width, visibleHeight: height }));
  }
  handleSelectMatrix(value: any) {
    // clear any sidebar settings when a new index is selected
    
    this.setState(() => ({
      currentMatrixId: value.value,
      selectText: value.label,
      availableIndexes: getIndexes(Inflation.Before),
      rangeFrequencies: [],
      selectedCell: null
    })); 
    setTimeout(() => {
      this.handleZoom(this.state.zoom);
      this.handleShowReturns(this.state.showReturns);
      this.handleSelectInflation(this.state.applyInflation);
    }, 10);
  }

  handleSelectInflation(value: boolean) {
    const inflation = value ? Inflation.After : Inflation.Before;
    const indexes = getIndexes(inflation);
    this.setState((state) => ({
      ...state,
      applyInflation: value,
      availableIndexes: indexes,
      rangeFrequencies: []
    }));
  }
  handleShowReturns(value: boolean) {
    this.setState(() => ({showReturns: value}));
  }
  handleSelectTimeframe(value: Coordinates) {
    this.setState(() => ({highlightReturnsPeriod: value}));
  }
  clearSelection(e) {
    setTimeout(() => {
      if (this.longPress || e.target.className === 'domgrid__cell' || e.target.className === 'domgrid__cell-value') return;
      this.setState(() => ({
        selectedCell: null,
        highlightReturnsPeriod: [0, 0],
      }));
    },500);
  }
  handleOnCellClick(coords: Coordinates) {
    if (coords == null) {
      this.setState(() => ({
        selectedCell: coords,
        highlightReturnsPeriod: [0, 0],
      }));
    } else {
      this.setState(() => ({
        selectedCell: coords,
      }));
    }
  }
  handleUpdateRangeFrequencies(rangeFrequencies: Array<number>) {
    if (rangeFrequencies !== null) {
      this.setState(() => ({
        rangeFrequencies: rangeFrequencies
      }));
    }
  }

  handleReset() {
    this.setState(() => ({
      dragOffsetX: 0
    }));
  }

  handleDraggable(dragMode: boolean) {
    this.setState(() => ({dragMode}));
  }
  handleZoom(zoom: number) {
    this.setState(() => ({zoom}));
    const zoomEle = document.getElementById("main");
    const matrixEle = document.getElementById("matrix");
    if (zoomEle && matrixEle) {
      const currentTransform = zoomEle.style.transform.replace(/scale\(.+?\)/,'');
      const width = window.innerWidth || document.body.clientWidth;
      //const height = (window.innerHeight || document.body.clientHeight) - 300;
      const matrixWidth = matrixEle.clientWidth;
      //const matrixHeight = matrixEle.getBoundingClientRect().height;
      let adjustedZoom = zoom*(width/(matrixWidth + 300));
      //todo: constrain by height
      //const overflowY = matrixHeight - height - 90;
      //if (zoom === 1 && (matrixHeight>height)) adjustedZoom = adjustedZoom * (height/matrixHeight);
      zoomEle.style.transform = `${currentTransform} scale(${adjustedZoom})`;
      let zoomMultiplier = 0;
      switch (zoom) {
        case 1.25:
            zoomMultiplier = 15;
            break;
        case 1.5:
            zoomMultiplier = 33;
            break;
        case 1.75:
            zoomMultiplier = 52;
            break;
        case 2:
            zoomMultiplier = 70;
            break;
        default:
            break;
      }
      let dragOffsetX = zoom === 1 ? 0 : -zoomMultiplier + '%';
      this.setState(() => ({
        dragOffsetX: dragOffsetX
      }));

      setTimeout(() => {
        const dragEle = document.querySelector('#matrix')
        if (dragEle && this.state.visibleWidth) {
          const dragEleRect = dragEle.getBoundingClientRect();
          if (dragEleRect.x > 0) {
            this.setState(() => ({
              dragOffsetX: 0
            }));
          }
        }
      });
    }
  }
  startDrag(e, data) {
      this.longPress = true;
  }
  stopDrag(e, data) {
    setTimeout(() => {
      this.handleDraggable(false);
      this.longPress = null;
    },50);
  }
  onDrag(e, data) {
    this.longPress = true;
    this.handleDraggable(true);
    Array.from(document.getElementsByClassName('hovered')).forEach((hovered) => hovered.classList.remove('hovered'));
    const hoverLabel = document.getElementById('hover-label');
    if (hoverLabel) hoverLabel.style.display = 'none';
  }
  render() {
    const {
      availableIndexes,
      currentMatrixId,
      selectedCell,
      hoveredCell,
      showReturns,
      rangeFrequencies,
      highlightReturnsPeriod,
      visibleHeight,
      visibleWidth,
      applyInflation,
      zoom,
      dragMode,
      selectText
    } = this.state;

    const matrixFiles = Object.keys(availableIndexes).map((key) => {
      const {
        meta: {name},
      } = availableIndexes[key] as IndexMatrix;

      let currentIndexOrder: number | undefined;
      indexOrder.forEach(([regex, order]) => {
        let match = name.match(regex);
        if (match && match.length && match.length > 0) {
          currentIndexOrder = order;
        }
      });

      // trim space at the beginning and end of string
      // because DFA will sometimes have extra spaces and the groups will break
      const group: string = nameToGroupMapper[name.trim()];

      // remove "FamaFrench" and "Index" from visual label in dropdown/compare table
      const formattedLabel: string = getShortMatrixName(name);

      return {
        value: key,
        label: name,
        formattedLabel: formattedLabel,
        group: group,
        groupOrder: indexGroupOrder[group], // group order
        indexOrder: currentIndexOrder, // index order
      };
    });
    const currentMatrix = availableIndexes[currentMatrixId];
    const {
      meta: {timePeriod},
    } = currentMatrix;

    const selectedTimePeriod = timePeriodFromCoords(
      selectedCell,
      timePeriod,
    ) as TimePeriod;

    const growthPeriod = selectedTimePeriod ? selectedTimePeriod : timePeriod;

    return (
      <div className="wrapper">
        <FadeIn transitionDuration={200} key={currentMatrixId}>
          <Topbar
            zoom={zoom}
            dragMode={dragMode}
            showReturns={showReturns}
            applyInflation={applyInflation}
            currentMatrix={currentMatrix}
            currentMatrixId={currentMatrixId}
            selectText={selectText}
            matrixFiles={matrixFiles}
            growthPeriod={growthPeriod}
            handleZoom={this.handleZoom}
            handleDraggable={this.handleDraggable}
            handleReset={this.handleReset}
            handleShowReturns={this.handleShowReturns}
            handleSelectInflation={this.handleSelectInflation}
            handleSelectMatrix={this.handleSelectMatrix}
            handleTimeframeSubmit={this.handleOnCellClick}
            handleSelectTimeframe={this.handleSelectTimeframe}
            highlightReturnsPeriod={highlightReturnsPeriod}
          />
            <Draggable
                bounds={{ bottom:0 }}
                position={ this.state.zoom === 1 ? {x: this.state.dragOffsetX, y: 0} : undefined }
                positionOffset={{ x: this.state.dragOffsetX, y:0 }}
                onStop={this.stopDrag.bind(this)}
                onStart={this.startDrag.bind(this)}
                onDrag={this.onDrag.bind(this)}
                onMouseDown={ this.clearSelection.bind(this) }
              >
                <div id="drag_container" ref="this.dragRef" style={{ transformOrigin: '0% 0% 0px', transform: 'scale(' + this.state.zoom*this.state.scale + ')' }}>
                  <div style={{ position: 'absolute', transformOrigin: '0% 0% 0px', marginTop: '58px' }} id="main"
                    className={ dragMode ? 'draggable' : 'pointer' }
                  >
                    <div>
                    <DomGrid
                      currentMatrix={currentMatrix}
                      currentMatrixId={currentMatrixId}
                      showReturns={showReturns}
                      selectedCell={selectedCell}
                      hoveredCell={hoveredCell}
                      timePeriod={timePeriod}
                      highlightReturnsPeriod={highlightReturnsPeriod}
                      visibleHeight={visibleHeight}
                      visibleWidth={visibleWidth}
                      onCellClick={this.handleOnCellClick}
                      handleUpdateRangeFrequencies={this.handleUpdateRangeFrequencies}
                      rangeFrequencies={rangeFrequencies}
                      dragMode={dragMode}
                      zoom={zoom}
                    />
                  </div>
                  </div>
                </div>
            </Draggable>
          </FadeIn>
          <AppControls
            availableIndexes={availableIndexes}
            currentMatrix={currentMatrix}
            currentMatrixId={currentMatrixId}
            matrixFiles={matrixFiles}
            growthPeriod={growthPeriod}
            applyInflation={applyInflation}
            showReturns={showReturns}
            rangeFrequencies={rangeFrequencies}
            highlightReturnsPeriod={highlightReturnsPeriod}
            visibleHeight={visibleHeight}
            visibleWidth={visibleWidth}
            handleSelectMatrix={this.handleSelectMatrix}
            handleSelectInflation={this.handleSelectInflation}
            handleShowReturns={this.handleShowReturns}
            handleSelectTimeframe={this.handleSelectTimeframe}
            handleTimeframeSubmit={this.handleOnCellClick}
          />
      </div>
    );
  }
}

export default Sentry.withProfiler(App);
