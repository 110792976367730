export enum Inflation {
    After = 'after',
    Before = 'before'
}

export type Coordinates = [number, number];

export interface IndexMatrix {
    rawRecordArray: number[][],
    meta: {
        name: string,
        currencyCode: string,
        timePeriod: [number, number]
        group: string,
        order: string,
    }
}

export type MatrixFile = {
    value: string,
    label: string,
    formattedLabel: string,
    group: string,
    groupOrder: number,
    indexOrder: number,
};

export type MatrixFiles = MatrixFile[];

export interface GroupOrder {
    key: string,
    value: number
}

export type GrowthPeriod = [number, number];

export type TimePeriod = [number, number] | null;