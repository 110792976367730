import React from "react";
import PropTypes from "prop-types";
import ReactTable from "react-table-6";

import GrowthChart from "../GrowthChart";
import {
  getAnnualizedReturns,
  getStandardDeviation,
  getTotalReturns,
  getFormattedPercent,
  getFormattedStandardDeviation,
  getFormattedCurrency,
} from "../../../utilities";

import "react-table-6/react-table.css";
import "./MatrixDetail.css";

const MatrixDetail = ({
  startYear,
  endYear,
  numberOfYears,
  chartData,
  currencyCode,
}) => {
  const annualizedReturns = getAnnualizedReturns(chartData);
  const standardDeviation = getStandardDeviation(chartData);
  const totalReturns = getTotalReturns(chartData);
  const growthOfDollar = getFormattedCurrency(
    chartData[chartData.length - 1].growthOfDollar,
    2,
    currencyCode
  );
  const data = [
    { name: "Investment Period", val: `${startYear} - ${endYear}` },
    { name: "Duration", val: numberOfYears },
    {
      name: "Annualized Return",
      val: `${getFormattedPercent(annualizedReturns)}`,
    },
    {
      name: "Standard Deviation",
      val: `${getFormattedStandardDeviation(standardDeviation)}*`,
    },
    { name: "Total Return", val: `${getFormattedPercent(totalReturns)}` },
  ];
  return (
    <React.Fragment>
      <div className="data__table">
        <ReactTable
          data={data}
          columns={[
            {
              // Header: "Detail Name",
              accessor: "name",
              className: "text-left",
            },
            {
              // Header: "Detail val",
              accessor: "val",
              className: "text-right",
            },
          ]}
          defaultPageSize={data.length}
          className="-striped -highlight"
          showPagination={false}
          TheadComponent={() => null}
        />
        <div className="data__note">
          * Please note that this number is computed from annual data and may
          differ from data computed with monthly data.
        </div>
      </div>
      {/* TODO: chart height can be the maximum available height on container */}
      <div className="data__chart">
        <GrowthChart
          chartData={chartData}
          growthOfDollar={growthOfDollar}
          currencyCode={currencyCode}
        />
      </div>
    </React.Fragment>
  );
};

MatrixDetail.propTypes = {
  startYear: PropTypes.number.isRequired,
  endYear: PropTypes.number.isRequired,
  numberOfYears: PropTypes.string.isRequired,
  chartData: PropTypes.array.isRequired, // todo define shape
  currencyCode: PropTypes.string.isRequired, // todo define shape
};

export default MatrixDetail;
