import Fama_French_Emerging_Markets_Growth_Index from "./json/Fama_French_Emerging_Markets_Growth_Index.json";
import Fama_French_Emerging_Markets_Index from "./json/Fama_French_Emerging_Markets_Index.json";
import Fama_French_Emerging_Markets_Small_Cap_Index from "./json/Fama_French_Emerging_Markets_Small_Cap_Index.json";
import Fama_French_Emerging_Markets_Value_Index from "./json/Fama_French_Emerging_Markets_Value_Index.json";
import Fama_French_International_Growth_Index from "./json/Fama_French_International_Growth_Index.json";
import Fama_French_International_Market_Index from "./json/Fama_French_International_Market_Index.json";
import Fama_French_International_Value_Index from "./json/Fama_French_International_Value_Index.json";
import Fama_French_Total_US_Market_Research_Index from "./json/Fama_French_Total_US_Market_Research_Index.json";
import Fama_French_US_Growth_Research_Index from "./json/Fama_French_US_Growth_Research_Index.json";
import Fama_French_US_High_Profitability_Index from "./json/Fama_French_US_High_Profitability_Index.json";
import Fama_French_US_Large_Cap_Research_Index from "./json/Fama_French_US_Large_Cap_Research_Index.json";
import Fama_French_US_Large_Growth_Research_Index from "./json/Fama_French_US_Large_Growth_Research_Index.json";
import Fama_French_US_Large_Neutral_Research_Index from "./json/Fama_French_US_Large_Neutral_Research_Index.json";
import Fama_French_US_Large_Value_Research_Index from "./json/Fama_French_US_Large_Value_Research_Index.json";
import Fama_French_US_Low_Profitability_Index from "./json/Fama_French_US_Low_Profitability_Index.json";
import Fama_French_US_Small_Cap_Research_Index from "./json/Fama_French_US_Small_Cap_Research_Index.json";
import Fama_French_US_Small_Growth_Research_Index from "./json/Fama_French_US_Small_Growth_Research_Index.json";
import Fama_French_US_Small_Neutral_Research_Index from "./json/Fama_French_US_Small_Neutral_Research_Index.json";
import Fama_French_US_Small_Value_Research_Index from "./json/Fama_French_US_Small_Value_Research_Index.json";
import Fama_French_US_Value_Research_Index from "./json/Fama_French_US_Value_Research_Index.json";
import Five_Year_US_Treasury_Notes from "./json/Five_Year_US_Treasury_Notes.json";
import Long_Term_Government_Bonds from "./json/Long_Term_Government_Bonds.json";
import One_Month_US_Treasury_Bills from "./json/One_Month_US_Treasury_Bills.json";

import Fama_French_Asia_Pacific_Growth_Index from "./json/country/Fama_French_Asia_Pacific_Growth_Index.json";
import Fama_French_Asia_Pacific_Market_Index from "./json/country/Fama_French_Asia_Pacific_Market_Index.json";
import Fama_French_Asia_Pacific_Value_Index from "./json/country/Fama_French_Asia_Pacific_Value_Index.json";
import Fama_French_Australia_Growth_Index from "./json/country/Fama_French_Australia_Growth_Index.json";
import Fama_French_Australia_Market_Index from "./json/country/Fama_French_Australia_Market_Index.json";
import Fama_French_Australia_Value_Index from "./json/country/Fama_French_Australia_Value_Index.json";
import Fama_French_Canada_Growth_Index from "./json/country/Fama_French_Canada_Growth_Index.json";
import Fama_French_Canada_Market_Index from "./json/country/Fama_French_Canada_Market_Index.json";
import Fama_French_Canada_Value_Index from "./json/country/Fama_French_Canada_Value_Index.json";
import Fama_French_Europe_and_Scandinavia_Growth_Index from "./json/country/Fama_French_Europe_and_Scandinavia_Growth_Index.json";
import Fama_French_Europe_and_Scandinavia_Market_Index from "./json/country/Fama_French_Europe_and_Scandinavia_Market_Index.json";
import Fama_French_Europe_and_Scandinavia_Value_Index from "./json/country/Fama_French_Europe_and_Scandinavia_Value_Index.json";
import Fama_French_Japan_Growth_Index from "./json/country/Fama_French_Japan_Growth_Index.json";
import Fama_French_Japan_Market_Index from "./json/country/Fama_French_Japan_Market_Index.json";
import Fama_French_Japan_Value_Index from "./json/country/Fama_French_Japan_Value_Index.json";
import Fama_French_UK_Growth_Index from "./json/country/Fama_French_UK_Growth_Index.json";
import Fama_French_UK_Market_Index from "./json/country/Fama_French_UK_Market_Index.json";
import Fama_French_UK_Value_Index from "./json/country/Fama_French_UK_Value_Index.json";

export const AvailableIndexes = {
  Fama_French_Emerging_Markets_Growth_Index,
  Fama_French_Emerging_Markets_Index,
  Fama_French_Emerging_Markets_Small_Cap_Index,
  Fama_French_Emerging_Markets_Value_Index,
  Fama_French_International_Growth_Index,
  Fama_French_International_Market_Index,
  Fama_French_International_Value_Index,
  Fama_French_Total_US_Market_Research_Index,
  Fama_French_US_Growth_Research_Index,
  Fama_French_US_High_Profitability_Index,
  Fama_French_US_Large_Cap_Research_Index,
  Fama_French_US_Large_Growth_Research_Index,
  Fama_French_US_Large_Neutral_Research_Index,
  Fama_French_US_Large_Value_Research_Index,
  Fama_French_US_Low_Profitability_Index,
  Fama_French_US_Small_Cap_Research_Index,
  Fama_French_US_Small_Growth_Research_Index,
  Fama_French_US_Small_Neutral_Research_Index,
  Fama_French_US_Small_Value_Research_Index,
  Fama_French_US_Value_Research_Index,
  Five_Year_US_Treasury_Notes,
  Long_Term_Government_Bonds,
  One_Month_US_Treasury_Bills,
  
  Fama_French_Asia_Pacific_Growth_Index,
  Fama_French_Asia_Pacific_Market_Index,
  Fama_French_Asia_Pacific_Value_Index,
  Fama_French_Australia_Growth_Index,
  Fama_French_Australia_Market_Index,
  Fama_French_Australia_Value_Index,
  Fama_French_Canada_Growth_Index,
  Fama_French_Canada_Market_Index,
  Fama_French_Canada_Value_Index,
  Fama_French_Europe_and_Scandinavia_Growth_Index,
  Fama_French_Europe_and_Scandinavia_Market_Index,
  Fama_French_Europe_and_Scandinavia_Value_Index,
  Fama_French_Japan_Growth_Index,
  Fama_French_Japan_Market_Index,
  Fama_French_Japan_Value_Index,
  Fama_French_UK_Growth_Index,
  Fama_French_UK_Market_Index,
  Fama_French_UK_Value_Index,
};
