import { AvailableIndexes as BeforeInflationIndexes } from './before_inflation/index';
import { AvailableIndexes as AfterInflationIndexes } from './after_inflation/index';

interface AvailableIndexesType {
  [key: string]: {};
};

const AvailableIndexes: AvailableIndexesType = {
    'before': BeforeInflationIndexes,
    'after': AfterInflationIndexes,
};

export function getIndexes(inflation='before') {
    return AvailableIndexes[inflation];
}

interface IndexGroupOrderType {
  [key: string]: number;
}

export const indexGroupOrder: IndexGroupOrderType = {
  "US Fixed Income (1927)": 50,
  "Fama/French US Stock Research Indexes (1927)": 100,
  "Fama/French US Indexes (1964)": 200,
  "Fama/French US Bonds (1927)": 300,
  "Fama/French International Indexes (1975)": 400,
  "Fama/French EM Indexes (1990)": 1100,
  "Fama/French Asia Pacific Indexes (1977)": 500,
  "Fama/French Australia Indexes (1977)": 530,
  "Fama/French Canada Indexes (1977)": 560,
  "Fama/French Europe Indexes (1977)": 590,
  "Fama/French Japan Indexes (1977)": 620,
  "Fama/French UK Indexes (1977)": 650,
};

export type IndexOrderType = [RegExp, number][];

// order of each regex matters
// Each one will be assigned a rank/order based on it's position in the array. 
// The matrixes are sorted in order with the ones at the top of the array being the first.
export const indexOrder: IndexOrderType = [
  [/Market/g],
  [/Growth/g],
  [/Value/g],
  [/Large Cap/g],
  [/Large Growth/g],
  [/Large Neutral/g],
  [/Large Value/g],
  [/Small Cap/g],
  [/Small Growth/g],
  [/Small Neutral/g],
  [/Small Value/g],
  [/Low/g],
  [/High/g],
  [/One-Month|One Month/g],
  [/Five-Year|Five Year/g],
  [/Long-Term|Long Term/g],
].map(([regex], index) => {
  // assign a random order value based on it's index in the array
  let order = (index + 1) * 100;
  return [regex, order];
});

interface NameToGroupMapperType {
  [key: string]: string;
}

export const nameToGroupMapper: NameToGroupMapperType = {

  // Fama/French EM Indexes (1990)
  "Fama/French Emerging Markets Growth Index": "Fama/French EM Indexes (1990)",
  "Fama/French Emerging Markets Index": "Fama/French EM Indexes (1990)",
  "Fama/French Emerging Markets Small Cap Index":
    "Fama/French EM Indexes (1990)",
  "Fama/French Emerging Markets Value Index": "Fama/French EM Indexes (1990)",

  // Fama/French International Indexes (1975)
  "Fama/French International Growth Index (CAD)":
    "Fama/French International Indexes (1975)",
  "Fama/French International Growth Index":
    "Fama/French International Indexes (1975)",
  "Fama/French International Market Index":
    "Fama/French International Indexes (1975)",
  "Fama/French International Value Index (CAD)":
    "Fama/French International Indexes (1975)",
  "Fama/French International Value Index":
    "Fama/French International Indexes (1975)",

  // Fama/French US Indexes (1927)
  "Fama/French Total US Market Research Index": "Fama/French US Stock Research Indexes (1927)",
  "Fama/French US Value Research Index": "Fama/French US Stock Research Indexes (1927)",
  "Fama/French US Growth Research Index": "Fama/French US Stock Research Indexes (1927)",

  "Fama/French US Large Cap Research Index": "Fama/French US Stock Research Indexes (1927)",
  "Fama/French US Large Growth Research Index": "Fama/French US Stock Research Indexes (1927)",
  "Fama/French US Large Neutral Research Index":"Fama/French US Stock Research Indexes (1927)",
  "Fama/French US Large Value Research Index": "Fama/French US Stock Research Indexes (1927)",

  "Fama/French US Small Cap Research Index": "Fama/French US Stock Research Indexes (1927)",
  "Fama/French US Small Growth Research Index": "Fama/French US Stock Research Indexes (1927)",
  "Fama/French US Small Neutral Research Index":
    "Fama/French US Stock Research Indexes (1927)",
  "Fama/French US Small Value Research Index": "Fama/French US Stock Research Indexes (1927)",

  // Fama/French US Indexes (1964)
  "Fama/French US High Profitability Index": "Fama/French US Indexes (1964)",
  "Fama/French US Low Profitability Index": "Fama/French US Indexes (1964)",

  // US Fixed Income (1927)
  "Five-Year US Treasury Notes": "US Fixed Income (1927)",
  "Long-Term Government Bonds": "US Fixed Income (1927)",
  "One-Month US Treasury Bills": "US Fixed Income (1927)",

  // Fama/French Developed Country Indexes (1977)
  "Fama/French Asia Pacific Growth Index": "Fama/French Asia Pacific Indexes (1977)",
  "Fama/French Asia Pacific Market Index": "Fama/French Asia Pacific Indexes (1977)",
  "Fama/French Asia Pacific Value Index": "Fama/French Asia Pacific Indexes (1977)",
  "Fama/French Australia Growth Index": "Fama/French Australia Indexes (1977)",
  "Fama/French Australia Market Index": "Fama/French Australia Indexes (1977)",
  "Fama/French Australia Value Index": "Fama/French Australia Indexes (1977)",
  "Fama/French Canada Growth Index": "Fama/French Canada Indexes (1977)",
  "Fama/French Canada Market Index": "Fama/French Canada Indexes (1977)",
  "Fama/French Canada Value Index": "Fama/French Canada Indexes (1977)",
  "Fama/French Europe and Scandinavia Growth Index": "Fama/French Europe Indexes (1977)",
  "Fama/French Europe and Scandinavia Market Index": "Fama/French Europe Indexes (1977)",
  "Fama/French Europe and Scandinavia Value Index": "Fama/French Europe Indexes (1977)",
  "Fama/French Japan Growth Index": "Fama/French Japan Indexes (1977)",
  "Fama/French Japan Market Index": "Fama/French Japan Indexes (1977)",
  "Fama/French Japan Value Index": "Fama/French Japan Indexes (1977)",
  "Fama/French UK Growth Index": "Fama/French UK Indexes (1977)",
  "Fama/French UK Market Index": "Fama/French UK Indexes (1977)",
  "Fama/French UK Value Index": "Fama/French UK Indexes (1977)",

};


/* 
    Jeff's preferred grouping and order in dropdown
    ---

    US Fixed Income (1927)
        Five-Year US Treasury Notes
        Long-Term Government Bonds
        One-Month US Treasury Bills
    
    Fama/French US Indexes (1927)
        Total US Market
        US Growth
        US Value
        US Large Cap
        US Large Growth
        US Large Value
        US Large Neutral
        US Small Cap
        US Small Growth
        US Small Value
        US Small Neutral

    Fama/French US Indexes (1964)
        US Low Profitability
        US High Profitability

    Fama/French International Indexes (1975)
        International Market
        International Growth
        International Value

    Fama/French EM Indexes (1990)
        Emerging Markets
        Emerging Markets Growth
        Emerging Markets Value
        Emerging Markets Small Cap

    Note that this adds up to 45 indexes. 
    Let’s leave off the FF Int’l Growth and Value indexes in (CAD) for now.
*/
