import React from "react";
import PropTypes from "prop-types";
import Drawer from "rc-drawer";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import DrawerHandle from "./components/DrawerHandle";
import MatrixDetail from "./components/MatrixDetail";
import CompareTable from "./components/CompareTable";
import { pluralize, getReturnsByYear } from "../utilities";

import "rc-drawer/assets/index.css";
import "react-tabs/style/react-tabs.css";
import "./DrawerContainer.css";

function DrawerContainer({
  title,
  availableIndexes,
  matrixFiles,
  showDrawer,
  handleToggleDrawer,
  currentMatrix,
  currentMatrixName,
  growthPeriod,
  tabIndex,
  handleSelectTabIndex,
}) {
  const [startYear, endYear] = growthPeriod;
  const count = endYear - startYear + 1;
  const numberOfYears = pluralize(count, "year", "s");
  const chartData = getReturnsByYear(currentMatrix, { startYear, endYear });
  const {
    meta: { currencyCode },
  } = currentMatrix;
  return (
    <React.Fragment>
      <Drawer
        handler={false}
        placement={"bottom"}
        level={null} // overlay
        width={"100%"}
        height={"55%"}
        open={showDrawer}
        onClose={handleToggleDrawer}
        onHandleClick={handleToggleDrawer}
      >
        <React.Fragment>
          <DrawerHandle
            title={title}
            isOpen={showDrawer}
            handleClick={handleToggleDrawer}
          />
          <Tabs selectedIndex={tabIndex} onSelect={handleSelectTabIndex}>
            <TabList>
              <Tab>Detail</Tab> {/* tab index 0 */}
              <Tab>Compare</Tab> {/* tab index 1 */}
            </TabList>
            <TabPanel>
              <MatrixDetail
                startYear={startYear}
                endYear={endYear}
                numberOfYears={numberOfYears}
                chartData={chartData}
                currencyCode={currencyCode}
              />
            </TabPanel>
            <TabPanel>
              <CompareTable
                matrixFiles={matrixFiles}
                availableIndexes={availableIndexes}
                startYear={startYear}
                endYear={endYear}
              />
            </TabPanel>
          </Tabs>
        </React.Fragment>
      </Drawer>
    </React.Fragment>
  );
}

DrawerContainer.propTypes = {
  title: PropTypes.string.isRequired,
  availableIndexes: PropTypes.any.isRequired,
  showDrawer: PropTypes.bool.isRequired,
  handleToggleDrawer: PropTypes.func.isRequired,
  currentMatrix: PropTypes.any.isRequired, // TODO: define shape
  currentMatrixName: PropTypes.string.isRequired,
  growthPeriod: PropTypes.array.isRequired, // TODO: define shape [1994, 2005]?
  tabIndex: PropTypes.number.isRequired,
  handleSelectTabIndex: PropTypes.func.isRequired,
};

export default DrawerContainer;
