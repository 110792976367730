import React, { useState, useEffect } from "react";
import Select from "react-select";

import ChevronToggle from "./components/ChevronToggle";
import { getShortMatrixName, rangeLabels, rangeColors } from "../utilities";

import { GrowthPeriod, IndexMatrix, MatrixFile } from "../types";

//import logo from "./interact-fa-logo.png";

import "./Sidebar.css";

interface Props {
  currentMatrix: IndexMatrix;
  currentMatrixId: string;
  currentMatrixName: string;
  matrixFiles: any;
  applyInflation: boolean;
  showReturns: boolean;
  rangeFrequencies: Array<number> | null;
  highlightReturnsPeriod: [number, number];
  growthPeriod: GrowthPeriod;
  visibleHeight: number | null;
  visibleWidth: number | null;

  handleSelectMatrix: (value: string, currentMatrixName: string) => void;
  handleShowReturns: (showReturns: boolean) => void;
  handleSelectInflation: (showInflation: boolean) => void;
  handleSelectTimeframe: (timeframe: [number, number]) => void;
  handleShowMetrics: () => void;
  handleShowComparisons: () => void;
  handleTimeframeSubmit: (coordinates: [number, number]) => void;
}

interface XYMap {
  [value: string]: number | string;
}

interface Group {
  [key: string]: [
    {
      value: string;
      label: string;
      group: string;
      groupOrder: number;
      indexOrder: number;
    }
  ];
}

function Sidebar({
  currentMatrix,
  currentMatrixId,
  currentMatrixName,
  matrixFiles,
  applyInflation,
  showReturns,
  rangeFrequencies,
  growthPeriod,
  visibleHeight,
  visibleWidth,
  handleSelectMatrix,
  handleShowReturns,
  handleSelectInflation,
  handleSelectTimeframe,
  handleShowMetrics,
  handleShowComparisons,
  handleTimeframeSubmit,
  highlightReturnsPeriod
}: Props) {
  const shouldShowSidebar = (height: number | null, width: number | null) => {
    if (height == null || width == null) {
      height = window.innerHeight;
      width = window.innerWidth;
    }
    if (height < 750 && width < 1200) {
      return false;
    } 
    const sidebar = document.getElementById("sidebar");
    return sidebar ? sidebar.className !== "sidebar-collapsed" : true;
  };
  const defaultSidebarVisibility = shouldShowSidebar(
    visibleHeight,
    visibleWidth
  );
  const [isSidebarVisible, setIsSidebarVisible] = useState(
    defaultSidebarVisibility
  );

  const handleChevronToggle = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };

  useEffect(() => {
    const defaultSidebarVisibility = shouldShowSidebar(
      visibleHeight,
      visibleWidth
    );
    setIsSidebarVisible(defaultSidebarVisibility);
  }, [visibleHeight, visibleWidth]);

  /*
        Build an X and Y hashtable with FROM and TO years
        So we can map the selected years in timeframe to coordinates. For example:

        x = { 1990: 0, 1991: 1, ...} y = { 2019: 0, 2018: 1, ...}

        When user asks for FROM:1990 & TO:2019, we can look up the coordinates as [0, 0].

    */
  const {
    meta: { timePeriod },
  } = currentMatrix;
  const [startYear, endYear] = timePeriod; // index matrix time period
  const [start, end] = growthPeriod; // selected time period
  const numberOfRows = endYear - startYear;

  let xMap: XYMap = {}; // TO
  let yMap: XYMap = {}; // FROM
  let tempStart = startYear;
  let tempEnd = endYear;

  // equivalent to do N times
  Array.from({ length: numberOfRows + 1 }).forEach((item, index) => {
    xMap[tempStart] = index;
    yMap[tempEnd] = index;
    tempStart = tempStart + 1;
    tempEnd = tempEnd - 1;
  });

  const [timeframeFrom, setTimeframeFrom] = useState<number>(start);
  const [timeframeTo, setTimeframeTo] = useState<number>(end);

  useEffect(() => {
    // the selected time period
    const [start, end] = growthPeriod;
    setTimeframeFrom(start);
    setTimeframeTo(end);
  }, [growthPeriod]);

  const handleChangeTimeframeFrom = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    setTimeframeFrom(parseInt(value));
  };
  const handleChangeTimeframeTo = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    setTimeframeTo(parseInt(value));
  };

  const handleSubmitTimeframe = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    // need to check if timeframe is valid
    const from = xMap[timeframeFrom];
    const to = yMap[timeframeTo];

    // check if they are numbers because checking for 0 && 0 will not work
    if (typeof from === "number" && typeof to === "number") {
      handleTimeframeSubmit([to, from]);
    }
    event.preventDefault();
    return false;
  };

  const timeframesToHighlight = [
    { value: "[0,0]", label: "None" },
    { value: "[0,5]", label: "1-5" },
    { value: "[5,10]", label: "6-10" },
    { value: "[10,15]", label: "11-15" },
  ];

  const handleChangeShowReturns = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    return value === "yes" ? handleShowReturns(true) : handleShowReturns(false);
  };
  const handleChangeInflation = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    return value === "after"
      ? handleSelectInflation(true)
      : handleSelectInflation(false);
  };
  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    return false;
  };

  // Group similar indexes in the dropdown and sort them by a predefined order
  let groups: Group = {};

  matrixFiles.forEach((item: MatrixFile) => {
    const { value, formattedLabel, group, groupOrder, indexOrder } = item;

    if (!groups[group]) {
      groups[group] = [] as any;
    }

    groups[group].push({
      value,
      label: formattedLabel,
      group,
      groupOrder,
      indexOrder,
    });
  });

  const groupedOptions = Object.entries(groups)
    .map(([key, value]) => {
      const { groupOrder } = value[0];
      const sortedOptions = value.sort((a, b) =>
        a.indexOrder > b.indexOrder ? 1 : -1
      );
      return {
        label: key,
        options: sortedOptions,
        groupOrder: groupOrder,
      };
    })
    .sort((a, b) => (a.groupOrder > b.groupOrder ? 1 : -1));

  const selectIndexStyles = {
    // @ts-ignore
    groupHeading: (styles) => ({
      ...styles,
      fontSize: "0.9rem",
      fontWeight: "bold",
      textTransform: "none",
      color: "black",
    }),
  };

  const renderRange = () => {
    if (rangeFrequencies) {
      let rows: any[] = []
      let total = 0;

      const topFrequency = rangeFrequencies.reduce((a, b) => Math.max(a, b), -Infinity);

      rangeLabels.forEach((rangeLabel,i) => {
        let rangeFrequency = rangeFrequencies[i] || 0;
        total += rangeFrequency;
        let isTop = topFrequency === rangeFrequency;
        let color = `rgba(${rangeColors[i]})`;
        let key = `range-${i}`
        rows.push(<div key={key} className="range-key-row" style={{ display: 'flex', alignItems: 'center', opacity: rangeFrequency === 0 ? '0.4' : 1, border: isTop ? '1px solid #c0c0c0' : ''}}><div style={{ background: color, width: '20px', height: '20px', display: 'inline-block' }}></div><div>{rangeLabel}</div><div>{rangeFrequency}</div></div>);
      });
      rows.push([<div key="range-header" className="range-key-row header"><div></div><div>Return Range</div><div>Frequency</div></div>]);
      rows = rows.reverse();
      rows.push([<div key="range-footer" className="range-key-row footer"><div></div><div></div><div>{total}</div></div>]);
      return rows;
    }
  }

  return (
    <div id="sidebar" className={!isSidebarVisible ? "sidebar-collapsed" : ""}>
      <form onSubmit={handleFormSubmit}>
        <div className="form__top">
          <div className="range-key">
            {renderRange()}
          </div>
          
        </div>
      </form>
    </div>
  );
}

export default Sidebar;
