import React from "react";
import PropTypes from "prop-types";

// Current version of react-table 7, which requires alot of changes to migrate to
// https://github.com/tannerlinsley/react-table/tree/v6.11.4
import ReactTable from "react-table-6";

// TODO: The data can be accessible via context - https://reactjs.org/docs/context.html
import {
  getReturnsByYear,
  getAnnualizedReturns,
  getStandardDeviation,
  getTotalReturns,
  getFormattedPercent,
  getFormattedCurrency,
  getFormattedStandardDeviation,
} from "../../../utilities";

import "react-table-6/react-table.css";
import "./CompareTable.css";

function CompareTable({ matrixFiles, availableIndexes, startYear, endYear }) {
  const tableData = matrixFiles
    .map((item) => {
      const { group, formattedLabel, value, groupOrder, indexOrder } = item;
      const currentMatrix = availableIndexes[value];
      const chartData = getReturnsByYear(currentMatrix, { startYear, endYear });
      const growthOfDollar =
        chartData.length === 0
          ? NaN
          : chartData[chartData.length - 1].growthOfDollar;
      const order = groupOrder + 1 + (indexOrder + 1) / 100;
      return {
        groupOrder: order,
        group: group,
        asset_classs: formattedLabel,
        annualized_return: getAnnualizedReturns(chartData),
        standard_deviation: getStandardDeviation(chartData),
        total_returns: getTotalReturns(chartData),
        dollar_growth: growthOfDollar,
      };
    })
    .sort((a, b) => (a.groupOrder > b.groupOrder ? 1 : -1));
  return (
    <React.Fragment>
      <div className="compare-table">
        <ReactTable
          data={tableData}
          columns={[
            {
              Header: "Group",
              accessor: "group",
              className: "",
              minWidth: 200,
            },
            {
              Header: "Asset Class",
              accessor: "asset_classs",
              className: "",
              minWidth: 300,
            },
            {
              Header: "Annualized Return",
              accessor: "annualized_return",
              className: "",
              Cell: ({ value }) => (
                <React.Fragment>{getFormattedPercent(value)}</React.Fragment>
              ),
            },
            {
              Header: "Standard Deviation",
              accessor: "standard_deviation",
              className: "",
              Cell: ({ value }) => (
                <React.Fragment>
                  {getFormattedStandardDeviation(value)}
                </React.Fragment>
              ),
            },
            {
              Header: "Total Return",
              accessor: "total_returns",
              className: "",
              Cell: ({ value }) => (
                <React.Fragment>{getFormattedPercent(value)}</React.Fragment>
              ),
            },
            {
              Header: "Growth of $1",
              accessor: "dollar_growth",
              className: "",
              Cell: ({ value }) => (
                <React.Fragment>{getFormattedCurrency(value)}</React.Fragment>
              ),
            },
          ]}
          style={{
            // This will force the table body to overflow and scroll, since there is not enough room
            height: "375px",
          }}
          // https://github.com/tannerlinsley/react-table/issues/1104
          // react-table can only sort by visible data, not using "order" field which we are not displaying
          defaultSorted={[]}
          showPagination={false}
          className="-striped -highlight"
          defaultPageSize={tableData.length}
        />
      </div>
    </React.Fragment>
  );
}

CompareTable.propTypes = {
  availableIndexes: PropTypes.any.isRequired, // TODO: define shape
  startYear: PropTypes.number.isRequired,
  endYear: PropTypes.number.isRequired,
};

export default CompareTable;
