import React from "react";
import PropTypes from "prop-types";
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

import { getFormattedCurrency } from "../../../utilities";

const GrowthChart = ({ chartData, growthOfDollar, currencyCode }) => {
  return (
    <ResponsiveContainer>
      <LineChart data={chartData}>
        <XAxis dataKey="year" />
        <YAxis
          dataKey="growthOfDollar"
          tickFormatter={(value) =>
            `${getFormattedCurrency(value, 0, currencyCode)}`
          }
          scale={chartData.length === 1 ? "linear" : "log"}
          type="number"
          domain={["auto", "auto"]}
        />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip
          formatter={(value) =>
            `${getFormattedCurrency(value, 2, currencyCode)}`
          }
        />
        <Legend formatter={(value) => `${value} - ${growthOfDollar}`} />
        <Line
          type="linear"
          stroke="green"
          name={`Growth of $1`}
          dataKey="growthOfDollar"
          dot={false}
          activeDot={{ r: 6 }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

GrowthChart.propTypes = {
  chartData: PropTypes.arrayOf(
    PropTypes.shape({
      year: PropTypes.number.isRequired,
      growthOfDollar: PropTypes.number.isRequired,
    })
  ).isRequired,
  growthOfDollar: PropTypes.string.isRequired,
  currencyCode: PropTypes.string.isRequired,
};

export default GrowthChart;
